.dropdown {
  float: left;
  overflow: hidden;
}

#dropBut {
  font-size: 260%;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: crimson;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #ddd;
  ;
}

.dropdown-content {
  float: left;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

#li_mobile {
  float: left;
}

.dropdown-content a {
  float: left;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  text-align: left;
  float: left;

}

hr {
  padding: 10px;
}

#logo {
  height: 30px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

hr {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

#logo_mobile {
  height: 40px;
  padding: 20px;
  float: right;
}

img {
  vertical-align: middle;
  padding-bottom: 5px;
}

#tutorial_imgs{
  max-width: 80%!important;
}

#navIcon {
  color: rgb(86, 86, 86);
  padding-right: 10px;
}

#navIcon {
  color: rgb(86, 86, 86);
  padding-right: 10px;
}

.modal-overlay {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}

.modal-content {
  height: 70%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.modal-footer .form-group label {
  margin-left: 3px;
}

.modal-footer {
  position: relative;
  border-top: 1px solid #efefef;
  margin-top: 30px;
}

.modal-footer .button-group {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.modal-footer .button-group a, .laterButton,
.modal-footer .button-group input[type='submit'] {
  background-color: crimson;
  margin: 0px 5px;
  padding: 9px 20px;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  border: 1px solid crimson;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
}

.modal-footer .button-group input:disabled,
.modal-footer .button-group input[disabled] {
  opacity: 0.6;
}

.modal-footer .button-group input[type='submit'] {
  background-color: green !important;
  border: 1px solid green !important;
}

.modal-form {
  margin-top: 20px;
}

.modalBtn {
  z-index: 999;
  position: relative;
}

.modal-box {
  display: block;
  background: white;
  width: 60%;
  height: 80%;
  padding: 1rem;
  border-radius: 1rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

.modal-box p {
  margin-right: 20px;
  margin-top: 0px;
}