#TosH1 {
  color: #080808;
  font-family: Raleway, sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none !important;
  transition: all 0.4s ease 0s;
}

#subhedding {
  text-decoration-line: underline;
  font-weight: bold;
}
#list {
  text-indent: 50px;
}

#dropdowns {
  margin-left: 10%;
  margin-right: 10%;
}

#effective {
  color: gray;
}

#legalHeader {
  text-decoration-style: dashed !important;
  text-decoration-color: red !important;
  text-decoration-line: underline !important;
}
