
.card-container {
    display: flex;
    justify-content: space-between; /* Space between cards */
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .card {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 32%; /* Make sure the cards fit within the container */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns text to the left */
    text-align: left; /* Ensures text is aligned to the left *
    align-items: center; /* Center content inside the card */
  }
  @media (max-width: 1000px) {
    .card-container {
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .card {
        background-color: #f4f4f4;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        width: 100%; /* Make sure the cards fit within the container */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        text-align: left;
        transition: transform 0.2s ease-in-out;
      }
      .description {
        height: 20%;
      }
      .feature-list {
        margin-top: 20px;
        height: 100%;
      }

  }
  
  .card h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .card h1 {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: bold;
  }
  
.card img {
    display: block;
    margin: 0 auto 15px auto; /* Centers the image and adds margin at the bottom */
    height: 140px;
    width: auto;
}
  .card p {
    color: #666;
    font-size: 15px;
  }
  .price-container {
    display: flex; /* Makes elements in this container align horizontally */
    align-items: baseline; /* Aligns the text on the same baseline */
    height: 45px;
    text-align: left;
  }
  .description {
    min-height: 155px;
  }
  .price-container h1 {
    margin-right: 5px; /* Add space between the price and the interval */
  }
  .price-container-invisable  h1 {
    margin-right: 5px; /* Add space between the price and the interval */
  }
  .feature-list {
    margin-top: 20px;
    min-height: 215px;
  }
  
  .feature-list h3 {
    margin-bottom: 10px;
  }
  
  .feature-list ul {
    list-style-type: disc; /* Bulleted list */
    padding-left: 20px; /* Indent the list from the left */
  }
  
  .feature-list li {
    margin-bottom: 8px; /* Space between list items */
    color: #666; /* You can adjust the color for the list items */
    font-size: 16px; /* Adjust font size */
  }
  .card button {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    background-color: rgb(104, 177, 114);
    color: white;
  }

#subscribe_button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 10px;
    font-size: 20px;
    background-color: rgb(104, 177, 114);
    color: white;
    height: 50px;
}
#buttonSpacer {
    height: 50px;
}